import React from "react";
import { Home,Information,About,TouristNotice,ContentList,ContentPage,ImageList,
  ImageCardList,NoticeList,Organization, DetailPage,Login, Guide, Register,Mine,
   PassWord,SysUserInfo,UserInfo,Appointment,MineAppointment, EntryGarden,Complaints,Advice,Find, App,MyOrder,MyPoint, Ticket, ForgetPwd, Plant  } from "../pages";
export type RouterType = {
    path: string,
    key: string,
    component: React.LazyExoticComponent<any>,
    root: String[],
    notExect? :boolean,
    children: RouterType[]
}

const HomeRouter: RouterType = {
  path: '/home',
  key: '/home',
  component: Home,
  root: [],
  children: [],
  notExect: false
}
const ContentListRouter: RouterType = {
  path: '/information/contentList/:name',
  key: '/contentList',
  component: ContentList,
  root: [],
  children: [],
  notExect: true
}
const ImageListRouter: RouterType = {
  path: '/information/imageList/:name',
  key: '/imageList',
  component: ImageList,
  root: [],
  children: [],
  notExect: true
}
const NoticeListRouter: RouterType = {
  path: '/information/noticeList/:name',
  key: '/noticeList',
  component: NoticeList,
  root: [],
  children: [],
  notExect: true
}
const InformationRouter: RouterType = {
  path: '/information',
  key: '/information',
  component: Information,
  root: [],
  children: [ContentListRouter, ImageListRouter, NoticeListRouter],
  notExect: true
}

const plantImageListRouter: RouterType = {
  path: '/plant/imageCardList/:name',
  key: '/plant/imageCardList',
  component: ImageCardList,
  root: [],
  children: [],
  notExect: true
}
const PlantRouter: RouterType = {
  path: '/plant',
  key: '/plant',
  component: Plant,
  root: [],
  children: [plantImageListRouter],
  notExect: true
}
const AboutContentListRouter: RouterType = {
  path: '/about/contentList/:name',
  key: '/contentList',
  component: ContentList,
  root: [],
  children: [],
  notExect: true
}
const AboutImageListRouter: RouterType = {
  path: '/about/imageList/:name',
  key: '/imageList',
  component: ImageList,
  root: [],
  children: [],
  notExect: true
}
const AboutImageCardListRouter: RouterType = {
  path: '/about/imageCardList/:name',
  key: '/imageCardList',
  component: ImageCardList,
  root: [],
  children: [],
  notExect: true
}
const AboutNoticeListRouter: RouterType = {
  path: '/about/noticeList/:name',
  key: '/noticeList',
  component: NoticeList,
  root: [],
  children: [],
  notExect: true
}
const AboutContentPageRouter: RouterType = {
  path: '/about/contentPage/:name',
  key: '/contentPage',
  component: ContentPage,
  root: [],
  children: [],
  notExect: true
}
const AboutOrganizationRouter: RouterType = {
  path: '/about/organization/:name',
  key: '/organization',
  component: Organization,
  root: [],
  children: [],
  notExect: true
}
const AboutRouter: RouterType = {
  path: '/about',
  key: '/about',
  component: About,
  root: [],
  children: [AboutContentListRouter, AboutImageListRouter, AboutNoticeListRouter,AboutContentPageRouter,AboutImageCardListRouter,AboutOrganizationRouter],
  notExect: true
}
const TouristNoticeContentListRouter: RouterType = {
  path: '/touristNotice/contentList/:name',
  key: '/contentList',
  component: ContentList,
  root: [],
  children: [],
  notExect: true
}
const TouristNoticeImageListRouter: RouterType = {
  path: '/touristNotice/imageList/:name',
  key: '/imageList',
  component: ImageList,
  root: [],
  children: [],
  notExect: true
}
const TouristNoticeImageCardListRouter: RouterType = {
  path: '/touristNotice/imageCardList/:name',
  key: '/imageCardList',
  component: ImageCardList,
  root: [],
  children: [],
  notExect: true
}
const TouristNoticeListRouter: RouterType = {
  path: '/touristNotice/noticeList/:name',
  key: '/noticeList',
  component: NoticeList,
  root: [],
  children: [],
  notExect: true
}
const TouristNoticeContentPageRouter: RouterType = {
  path: '/touristNotice/contentPage/:name',
  key: '/contentPage',
  component: ContentPage,
  root: [],
  children: [],
  notExect: true
}
const TouristNoticeRouter: RouterType = {
  path: '/touristNotice',
  key: '/touristNotice',
  component: TouristNotice,
  root: [],
  children: [TouristNoticeContentListRouter, TouristNoticeImageListRouter, TouristNoticeListRouter,TouristNoticeContentPageRouter,TouristNoticeImageCardListRouter],
  notExect: true
}
const DetailRouter: RouterType = {
    path: '/detail/:module/:component/:id',
    key: '/contentPage',
    component: DetailPage,
    root: [],
    children: [],
    notExect: true
}
const LoginRouter: RouterType = {
    path: '/login',
    key: '/login',
    component: Login,
    root: [],
    children: [],
    notExect: true
}
const TokenOutTimeRouter: RouterType = {
  path: '/home/TokenOutTime',
  key: '/home/TokenOutTime',
  component: Login,
  root: [],
  children: [],
  notExect: true
}
const RegisterRouter: RouterType = {
  path: '/register',
  key: '/register',
  component: Register,
  root: [],
  children: [],
  notExect: true
}
const GuideRouter: RouterType = {
    path: '/guide/:menuId',
    key: '/guide/:menuId',
    component: Guide,
    root: [],
    children: [],
    notExect: true
}
const PassWordRouter: RouterType = {
  path: '/mine/passWord',
  key: '/passWord',
  component: PassWord,
  root: [],
  children: [],
  notExect: true
}
const UserInfoRouter: RouterType = {
  path: '/mine/userInfo',
  key: '/userInfo',
  component: UserInfo,
  root: [],
  children: [],
  notExect: true
}
const TicketRouter: RouterType = {
  path: '/mine/ticket',
  key: '/userInfo',
  component: Ticket,
  root: [],
  children: [],
  notExect: true
}

const SysUserInfoRouter: RouterType = {
  path: '/mine/sys/sysUserInfo',
  key: '/sysUserInfo',
  component: SysUserInfo,
  root: [],
  children: [],
  notExect: true
}

const MyOrderRouter: RouterType = {
  path: '/mine/myOrder',
  key: '/myOrder',
  component: MyOrder,
  root: [],
  children: [],
  notExect: true
}


const MyPointRouter: RouterType = {
  path: '/mine/myPoints',
  key: '/myPoints',
  component: MyPoint,
  root: [],
  children: [],
  notExect: true
}
const MineAppointmentRouter: RouterType = {
  path: '/mine/myappointment',
  key: '/myAppointment',
  component: MineAppointment,
  root: [],
  children: [],
  notExect: true
}

const ComplaintsRouter: RouterType = {
  path: '/mine/complaints',
  key: '/complaints',
  component: Complaints,
  root: [],
  children: [],
  notExect: true
}

const AdviceRouter: RouterType = {
  path: '/mine/advice',
  key: '/advice',
  component: Advice,
  root: [],
  children: [],
  notExect: true
}

const FindRouter: RouterType = {
  path: '/mine/find',
  key: '/find',
  component: Find,
  root: [],
  children: [],
  notExect: true
}

const MineRouter: RouterType = {
    path: '/mine',
    key: '/mine',
    component: Mine,
    root: [],
    children: [TicketRouter, SysUserInfoRouter,MyPointRouter,UserInfoRouter,MyOrderRouter, PassWordRouter,MineAppointmentRouter,ComplaintsRouter,AdviceRouter,FindRouter],
    notExect: true
}
const app: RouterType = {
  path: '/app',
  key: '/app',
  component: App,
  root: [],
  children: [],
  notExect: true
}
const EntryGardenRouter: RouterType = {
  path: '/appointment/entryGarden',
  key: '/entryGarden',
  component: EntryGarden,
  root: [],
  children: [],
  notExect: true
}

const AppointmentRouter: RouterType = {
  path: '/appointment',
  key: '/appointment',
  component: Appointment,
  root: [],
  children: [EntryGardenRouter],
  notExect: true
}
const forgetPwdRouter: RouterType = {
  path: '/forgetPwd',
  key: '/forgetPwd',
  component: ForgetPwd,
  root: [],
  children: [],
  notExect: true
}

  // 总路由
  const Routers: RouterType[] = ([
    HomeRouter,
    InformationRouter,
    AboutRouter,
    TouristNoticeRouter,
    DetailRouter,
    // LoginRouter,
    GuideRouter,
    // RegisterRouter,
    MineRouter,
    // app,
    AppointmentRouter,
    forgetPwdRouter,
    TokenOutTimeRouter,
    PlantRouter
  ])
  
  export {
    Routers,
    HomeRouter,
    InformationRouter,
    AboutRouter,
    TouristNoticeRouter,
    MineRouter,
    AppointmentRouter,
    PlantRouter
  }
