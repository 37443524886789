/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Menu, Layout, Input, Tooltip, Button, message, Dropdown } from "antd";
import { SearchOutlined, DownOutlined } from "@ant-design/icons";
import logoImg from "../../asset/img/logo.png";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import "./index.css";
import { LoginInfo, UserInfo } from "../../store/type";
import { LOGINSTATE } from "../../store/enmu";
import { login, signout } from "../../store/actions";
import defaultImg from "../../asset/Login/defaultImg.png";
import male from "../../asset/Login/male.png";
import female from "../../asset/Login/female.png";
const info = () => {
  message.info("敬请期待！");
};
export interface ITopHeaderProps {
  scrollMore: Boolean;
}
interface IProps extends RouteComponentProps {
  scrollMore: Boolean;
  userInfo: UserInfo;
  state: LOGINSTATE;
  onSignout: () => {};
}
interface ITopHeaderState {}

const mapStateToProps = (
  state: LoginInfo
): {
  state: LOGINSTATE;
  userInfo?: UserInfo;
} => ({
  state: state.state,
  userInfo: state.userInfo ? state.userInfo : undefined,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onSignout: () => dispatch(signout()),
});

class TopHeader extends React.Component<IProps, ITopHeaderState> {
  constructor(props: IProps) {
    super(props);
    this.state = {};
  }
  componentDidMount() {}
  sigout = () => {
    localStorage.removeItem("userToken");
    localStorage.removeItem("user_info");
    localStorage.removeItem("state");
    message.success("退出成功");
    this.props.history.push("/home");
    this.props.onSignout();
  };

  // 监听props 同步数据
  // 绑定的路由组件中不支持这种方法
  // static getDerivedStateFromProps(nextProps: ITopHeaderProps,prevState: ITopHeaderState) {
  // }

  // componentWillReceiveProps() {}

  render() {
    const { Header } = Layout;
    const { scrollMore, userInfo, state } = this.props;
    const { SubMenu } = Menu;
    const pathname = this.props.history.location.pathname;
    const menu = (
      <Menu>
        <Menu.Item key="0">
          <Link to="/mine">个人中心</Link>
        </Menu.Item>
        <Menu.Item key="1">
          <p onClick={this.sigout}>退出登录</p>
        </Menu.Item>
      </Menu>
    );
    return (
      <div className="topHeader">
        <Header className={scrollMore ? "moreHeaderTop" : "topHeaderTop"}>
          <div className="logo" title="第十三届中国（徐州）国际园林博览会">
            <Link to="/home">
              <img width="100%" src={logoImg} alt="" />
            </Link>
          </div>
          {state === LOGINSTATE.SIGNIN && userInfo ? (
            <div className="headerFunctional">
              <div className="userImg">
                {(() => {
                  if (userInfo.avatar) {
                    console.log(userInfo.avatar)
                    return <div style={{background: 'url(' + userInfo.avatar + ') no-repeat', backgroundPosition: 'center', backgroundSize: '100% auto'}}></div>;
                  } else {
                    switch (userInfo.sex) {
                      case null:
                        return <div style={{background: 'url(' + defaultImg + ') no-repeat', backgroundPosition: 'center', backgroundSize: '100% auto'}}></div>;
                      case 1:
                        return <div style={{background: 'url(' + female + ') no-repeat', backgroundPosition: 'center', backgroundSize: '100% auto'}}></div>;
                      case 0:
                        return <div style={{background: 'url(' + male + ') no-repeat', backgroundPosition: 'center', backgroundSize: '100% auto'}}></div>;
                    }
                  }
                })()}
              </div>
              <Dropdown overlay={menu}>
                <div className="userName">
                  <p>{userInfo.username}</p>
                  <DownOutlined />
                </div>
              </Dropdown>
            </div>
          ) : (
            <div className="headerFunctional">
              {/* <Input bordered={false} allowClear style={{ width: '40%' }} suffix={
                        <Tooltip title="回车搜索">
                            <SearchOutlined style={{ color: '#048779' }} />
                        </Tooltip>
                        }/>
                        <div className="verticalSplit"></div> */}
              {/* <Link
                to="/login"
                style={{
                  marginLeft: "2% ",
                  marginRight: "2% ",
                  color: "#fff",
                  fontSize: "16px",
                }}
              >
                登录
              </Link> */}
              {/* <Link
                to="/register"
                style={{
                  background: "#048779",
                  border: "none",
                  marginLeft: "2%",
                  width: "25%",
                  fontSize: "16px",
                  padding: "3% 5%",
                  color: "#fff",
                }}
              >
                注册
              </Link> */}
            </div>
          )}

          <div className="menuStyle headMenu">
            <Menu selectedKeys={[pathname]} mode="horizontal">
              <Menu.Item key="/home">首页
                <Link to="/home"></Link>
              </Menu.Item>
              {/* <Menu.Item key="/appointment/entryGarden">预约入园
                <Link to="/appointment/entryGarden"></Link>
              </Menu.Item> */}
              {/* <Menu.Item key="/appointment/entryGarden" title="预约入园">
                <Link to="/appointment/entryGarden"></Link>
                <Menu.Item key="/appointment/entryGarden">
                  <Link to="/appointment/entryGarden">预约入园</Link>
                </Menu.Item>
              </Menu.Item> */}
              <SubMenu key="/about" title="关于园博">
                <Menu.Item key="/about/contentPage/introduction">
                  <Link to="/about/contentPage/introduction">园博简介</Link>
                </Menu.Item>
                {/* <Menu.Item key="/about/organization/org">
                  <Link to="/about/organization/org">组织结构</Link>
                </Menu.Item>
                <Menu.Item key="/about/imageCardList/honor">
                  <Link to="/about/imageCardList/honor">园博荣誉</Link>
                </Menu.Item>
                <Menu.Item key="/about/contentPage/spirit">
                  <Link to="/about/contentPage/spirit">园博精神</Link>
                </Menu.Item>
                <Menu.Item key="/about/contentList/memorabilia">
                  <Link to="/about/contentList/memorabilia">园博大事记</Link>
                </Menu.Item> */}
                <Menu.Item key="/about/imageCardList/promo">
                  <Link to="/about/imageCardList/promo">园博视讯</Link>
                </Menu.Item>
                {/* <Menu.Item key="/about/contentList/plan">
                  <Link to="/about/contentList/plan">园博规划</Link>
                </Menu.Item> */}
              </SubMenu>
              <SubMenu key="/touristNotice" title="游客须知">
                <Menu.Item key="/touristNotice/contentPage/searchOpeningTime">
                  <Link to="/touristNotice/contentPage/searchOpeningTime">
                    开放时间
                  </Link>
                </Menu.Item>
                <Menu.Item key="/touristNotice/ContentList/notice">
                  <Link to="/touristNotice/ContentList/notice">入园须知</Link>
                </Menu.Item>
                <Menu.Item key="/touristNotice/imageCardList/line">
                  <Link to="/touristNotice/imageCardList/line">路线推荐</Link>
                </Menu.Item>
                <Menu.Item key="/touristNotice/ContentList/trafficservice">
                  <Link to="/touristNotice/ContentList/trafficservice">
                    交通服务
                  </Link>
                </Menu.Item>
                <Menu.Item key="/touristNotice/ContentList/commonproblem">
                  <Link to="/touristNotice/ContentList/commonproblem">
                    常见问题
                  </Link>
                </Menu.Item>
              </SubMenu>
              <SubMenu key="/information" title="园博资讯">
                <Menu.Item key="/information/contentList/news">
                  <Link to="/information/contentList/news">园博新闻</Link>
                </Menu.Item>
                <Menu.Item key="/information/noticeList/announcement">
                  <Link to="/information/noticeList/announcement">
                    园博公告
                  </Link>
                </Menu.Item>
                {/* <Menu.Item key="/information/contentList/party">
                  <Link to="/information/contentList/party">党政工作</Link>
                </Menu.Item> */}
                <Menu.Item key="/information/contentList/event">
                  <Link to="/information/contentList/event">园博活动</Link>
                </Menu.Item>
                <Menu.Item key="/information/imageList/impression">
                  <Link to="/information/imageList/impression">园博印象</Link>
                </Menu.Item>
              </SubMenu>
              <Menu.Item key="/guide/0">游客攻略
                <Link to="/guide/0"></Link>
              </Menu.Item>
              
              <Menu.Item key="/plant/imageCardList/plantList">
                  <Link to="/plant/imageCardList/plantList">植物百科</Link>
                </Menu.Item>
              {/* <Menu.Item key="virtualTouring">
                <span onClick={info} title="虚拟游园">
                  虚拟游园
                </span>
              </Menu.Item> */}
              {/* <Menu.Item key="app">app下载
               <Link to="/app"></Link>
              </Menu.Item> */}
            </Menu>
          </div>
        </Header>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter<any, React.ComponentClass<IProps, ITopHeaderState>>(TopHeader));
